import { z } from 'zod';

export const WDAccountSchema = z.object({
  id: z.string(),
  crmAccountId: z.string(),
  isServiceProvider: z.boolean(),
  isParent: z.boolean(),
  isRoot: z.boolean(),
  isCustomer: z.boolean(),
  isReferrer: z.boolean(),
  isDemo: z.boolean(),
  portalUrl: z.string().nullable(),
  name: z.string(),
  serviceProviderId: z.string().nullable(),
  serviceProviderName: z.string().nullable()
});

type WDAccount = z.infer<typeof WDAccountSchema>;

export default WDAccount;
