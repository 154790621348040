import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { portalFetch } from '../libs/libAPI';
import useSessionStorageBackedState from '../libs/useSessionStorageBackedState';
import DropdownTreeNode from '../types/DropdownTreeNode';
import WDAccount, { WDAccountSchema } from '../types/WDAccount';
import WDEntitlementComponentPortal, {
  WDEntitlementComponentPortalSchema
} from '../types/WDEntitlementComponentPortal';
import { useUserContext } from './UserContext';

export interface IAccountContext {
  accountId: string;
  setAccountId: (accountId: string) => void;
  accountTree?: DropdownTreeNode[] | null;
  currentAccount?: WDAccount;
  currentAccountEntitlementComponents: WDEntitlementComponentPortal[];
  hasChildren: boolean;
}

const AccountContext = createContext<IAccountContext | null>(null);

AccountContext.displayName = 'AccountContext';

interface AccountContextProviderProps {
  children: React.ReactNode;
}

export const setSelectedAccount = (accountTree: DropdownTreeNode[], selectedAccountId: string): boolean => {
  let expandParent = false;
  accountTree.forEach((accountNode) => {
    accountNode.checked = accountNode.value === selectedAccountId;
    if (accountNode.checked) {
      expandParent = true;
    }
    if (setSelectedAccount(accountNode.children ?? [], selectedAccountId)) {
      accountNode.expanded = true;
      expandParent = true;
    }
  });

  return expandParent;
};

export const AccountContextProvider: React.FC<AccountContextProviderProps> = (props: AccountContextProviderProps) => {
  const userContext = useUserContext();
  const [selectedAccountId, setSelectedAccountId] = useSessionStorageBackedState<string>(`${userContext.apparentUser.id}-selectedAccount`, userContext.apparentUser.accountId);

  useEffect(() => {
    setSelectedAccountId(userContext.apparentUser.accountId);
  }, [userContext.apparentUser.accountId]);

  const accountsQuery = useQuery(['accounts', userContext.apparentUser.accountId], async () => {
    const response = await portalFetch(`${import.meta.env.VITE_API_URI}/v1/ux/accountstree`, await userContext.getApiToken(), {
      method: 'GET'
    }, userContext.apparentUserEmail, userContext.apparentUser.accountId);

    if (response.ok) {
      const data: DropdownTreeNode[] = await response.json();

      if (data.length > 0) {
        data[0].expanded = true;
        data[0].tagClassName = 'own-account';
      }

      return data;
    }

    return null;
  }, {
    cacheTime: 60 * 60 * 1000
  });

  const accountQuery = useQuery(['account', selectedAccountId], async () => {
    const response = await portalFetch(`${import.meta.env.VITE_API_URI}/v1/accounts/${selectedAccountId}`, await userContext.getApiToken(), {
      method: 'GET'
    }, userContext.apparentUserEmail, userContext.apparentUser.accountId);

    if (response.ok) {
      const data: WDAccount = await WDAccountSchema.parseAsync(await response.json());
      return data;
    }

    return undefined;
  }, {
    cacheTime: 15 * 60 * 1000
  });

  const entitlementComponentsQuery = useQuery(['entitlement-components', selectedAccountId], async () => {
    const response = await portalFetch(`${import.meta.env.VITE_API_URI}/v1/entitlements/component-portals`, await userContext.getApiToken(), {
      method: 'GET'
    }, userContext.apparentUserEmail, selectedAccountId);

    if (response.ok) {
      const data: WDEntitlementComponentPortal[] = await z.array(WDEntitlementComponentPortalSchema).parseAsync(await response.json());

      return data;
    }

    return undefined;
  }, {
    cacheTime: 15 * 60 * 1000
  });

  const accountTree = useMemo(() => {
    if (accountsQuery.data == null) {
      return null;
    }
    const accountsTree: DropdownTreeNode[] = JSON.parse(JSON.stringify(accountsQuery.data));
    setSelectedAccount(accountsTree, selectedAccountId);
    if (accountsTree.length > 0) {
      accountsTree[0].expanded = true;
      accountsTree[0].tagClassName = 'own-account';
    }
    return accountsTree;
  }, [selectedAccountId, accountsQuery.data]);

  const accountContext: IAccountContext = {
    accountId: selectedAccountId,
    setAccountId: setSelectedAccountId,
    accountTree,
    currentAccount: accountQuery.data,
    currentAccountEntitlementComponents: entitlementComponentsQuery.data ?? [],
    hasChildren: ((accountsQuery.data?.[0].children?.length ?? 0) > 0)
  };

  return (
    <AccountContext.Provider value={accountContext}>
      {props.children}
    </AccountContext.Provider>
  );
};

export const useAccountContext = (): IAccountContext => {
  const context = useContext(AccountContext);

  if (context === null) {
    throw Error('Must be used within AccountContextProvider');
  }

  return context;
};
