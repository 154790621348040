import React from 'react';

import { useUserContext } from '../../contexts/UserContext';
import { Permission } from '../../libs/libPermissions';

interface RequirePermissionProps {
  permission: Permission;
  children: React.ReactNode;
}

const RequirePermission: React.FC<RequirePermissionProps> = (props) => {
  const userContext = useUserContext();

  const hasPermission = userContext.hasPermission(props.permission);

  if (hasPermission) {
    return <>{props.children}</>;
  }

  return <></>;
};

export default RequirePermission;
