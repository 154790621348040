import { z } from 'zod';

export const WDUserSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  accountName: z.string(),
  accountId: z.string(), // TODO handle UUID
  accountIsServiceProvider: z.boolean(),
  accountIsDemo: z.boolean(),
  portalRole: z.string(),
  gimp: z.boolean(),
  email: z.string().email(),
  officePhone: z.string().nullable(),
  mobilePhone: z.string().nullable(),
  serviceProviderName: z.string().nullable(),
  serviceProviderId: z.string().nullable(),
  permissions: z.array(z.string()),
  portalUrl: z.string().optional().nullable()
});

type WDUser = z.infer<typeof WDUserSchema>;

export default WDUser;
