import React from 'react';

import useTitle from '../../libs/useTitle';
import { ErrorComponentProps } from '../../types/ErrorComponentProps';

const Error: React.FC<ErrorComponentProps> = (props: ErrorComponentProps) => {
  let devDetails = null;

  useTitle('Error');

  if (import.meta.env.DEV) {
    devDetails = <>
      <div className='hidden lg:block text-left pt-6'>
        <p className=''>Name: {props.error.name}</p>
        <p className='text-sm'>Message: {props.error.message}</p>
        <pre className='overflow-y-auto text-xs'>{props.error.stack}</pre>
      </div>
    </>;
  }

  return <>
  <div className='flex -h-screen-nav'>
      <div className='flex flex-col mx-auto my-auto'>
        <div className='mx-auto'>
          <svg className={'fill-foreground h-60'}>
            <use href={'/icons.svg#cybro-sitting'} />
          </svg>
        </div>
        <h1 className='text-xl md:text-4xl pb-4 text-center'>Well, this is embarrassing...</h1>
        <h2 className='text-lg max-w-4xl'>Sorry, this is not working properly. We now know about this mistake and are working to fix it.</h2>
        <p>&nbsp;</p>
        <h2 className='text-lg'>In the meantime, here is what you can do:</h2>
        <h3 className='text-lg'><span className='bi bi-arrow-clockwise px-3' /><span className='font-bold'>Refresh the page</span> (sometimes this helps).</h3>
        <h3 className='text-lg'><span className='bi bi-clock px-3' /><span className='font-bold'>Try again</span> in 30 minutes.</h3>
        <h3 className='text-lg'><span className='bi bi-chat-left-fill px-3' /><span className='font-bold'>Provide Feedback</span> by clicking the button on the bottom of the page.</h3>
        <div className={`${devDetails === null ? 'md:h-40' : ''}`}></div>
        {devDetails}
      </div>
  </div>
  </>;
};

export default Error;
