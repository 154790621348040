import React, { createContext, useContext } from 'react';

import { captureException, withScope } from '@sentry/core';

import { ErrorComponentProps } from '../../types/ErrorComponentProps';
import { IError } from '../../types/IError';

type IErrorInfo = ({
  hasError: false;
  error?: IError;
  componentStack?: React.ErrorInfo['componentStack'];
  eventId?: string;
} | {
  hasError: true;
  error: IError;
  componentStack: React.ErrorInfo['componentStack'];
  eventId: string;
});

interface IErrorBoundryContext {
  errorInfo: IErrorInfo;
  reset: () => void;
}

const ErrorBoundryContext = createContext<IErrorBoundryContext>({ reset: () => {}, errorInfo: { hasError: false } });

ErrorBoundryContext.displayName = 'ErrorBoundryContext';

const useErrorBoundry = (): IErrorBoundryContext => {
  return useContext(ErrorBoundryContext);
};

interface HasChildrenProps {
  children: React.ReactNode;
}

interface ErrorBoundryErrorProps {
  component: React.ComponentType<ErrorComponentProps>;
}

interface ErrorBoundryProps extends HasChildrenProps {
  key: string;
}

export class ErrorBoundry extends React.Component<ErrorBoundryProps, IErrorInfo> {
  static OK: React.FC<HasChildrenProps>;
  static Error: React.FC<ErrorBoundryErrorProps>;
  static SimpleError: React.FC<HasChildrenProps>;

  constructor(props: ErrorBoundryProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  reset(): void {
    this.setState({ hasError: false });
  }

  componentDidUpdate(prevProps: Readonly<ErrorBoundryProps>, prevState: Readonly<IErrorInfo>, snapshot?: any): void {
    if (this.state.hasError && prevState.hasError && prevProps.key !== this.props.key) {
      this.setState({ hasError: false });
    }
  }

  // eslint-disable-next-line n/handle-callback-err
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    withScope(scope => {
      const errorBoundryError = new Error(error.message);
      errorBoundryError.name = `React ErrorBoundry ${errorBoundryError.name}`;
      errorBoundryError.stack = errorInfo.componentStack;

      error.cause = errorBoundryError;

      const eventId = captureException(error, { contexts: { react: { componentStack: errorInfo.componentStack } } });

      // TODO show user feedback dialog

      this.setState({
        hasError: true,
        error: {
          name: error.name,
          message: error.message,
          stack: error.stack
        },
        componentStack: errorInfo.componentStack,
        eventId
      });
    });
  }

  render(): React.ReactNode {
    const context: IErrorBoundryContext = {
      reset: this.reset.bind(this),
      errorInfo: this.state
    };

    return <ErrorBoundryContext.Provider value={context}>{this.props.children}</ErrorBoundryContext.Provider>;
  }
};

ErrorBoundry.OK = (props: HasChildrenProps) => {
  const context = useErrorBoundry();

  if (context.errorInfo.hasError) {
    return <></>;
  }

  return <>{props.children}</>;
};
ErrorBoundry.OK.displayName = 'ErrorBoundry.OK';

ErrorBoundry.Error = (props: ErrorBoundryErrorProps) => {
  const context = useErrorBoundry();

  if (!context.errorInfo.hasError) {
    return <></>;
  }

  return <props.component error={context.errorInfo.error} resetErrorBoundry={context.reset} />;
};

ErrorBoundry.Error.displayName = 'ErrorBoundry.Error';

ErrorBoundry.SimpleError = (props: HasChildrenProps) => {
  const context = useErrorBoundry();

  if (!context.errorInfo.hasError) {
    return <></>;
  }

  return <>{props.children}</>;
};

ErrorBoundry.SimpleError.displayName = 'ErrorBoundry.SimpleError';
