import { z } from 'zod';

export const WDEntitlementComponentPortalSchema = z.object({
  label: z.string(),
  url: z.string().nullable(),
  urlRequired: z.boolean()
});

type WDEntitlementComponentPortal = z.infer<typeof WDEntitlementComponentPortalSchema>;

export default WDEntitlementComponentPortal;
