/* eslint-disable quote-props */
import { useAccountContext } from '../contexts/AccountContext';
import { useUserContext } from '../contexts/UserContext';

export const fetchWithToken = async (url: RequestInfo | URL, token: string, options: RequestInit): Promise<Response> => {
  const result = await fetch(url, {
    cache: 'no-cache',
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`
    }
  });

  return result;
};

export const portalFetch = async (url: RequestInfo | URL, token: string, options: RequestInit, userEmail?: string, account?: string): Promise<Response> => {
  const headers: { [name: string]: string } = {};

  if (userEmail != null) {
    headers['X-WD-Email'] = userEmail;
  }

  if (account != null) {
    headers['X-WD-Account'] = account;
  }

  const result = await fetchWithToken(url, token, {
    ...options,
    headers: {
      ...options.headers,
      ...headers
    }
  });

  return result;
};

export interface UseApi {
  fetch: (url: RequestInfo | URL, options: RequestInit) => Promise<Response>;
}

export const useAPI = (): UseApi => {
  const { getApiToken, apparentUserEmail } = useUserContext();
  const { accountId } = useAccountContext();

  const apiFetch = async (url: RequestInfo | URL, options: RequestInit, impersonateEmail?: string): Promise<Response> => {
    // const { audience, scope, ...fetchOptions } = options;
    const accessToken = await getApiToken();

    const result = await portalFetch(url, accessToken, options, impersonateEmail ?? apparentUserEmail, accountId);

    return result;
  };

  const use: UseApi = {
    fetch: apiFetch
  };

  return use;
};
