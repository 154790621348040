export enum PermissionVerb {
  Any = '*',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete'
};

export enum PermissionContext {
  Any = '*',
  Self = 'self',
  Account = 'account'
};

export class Permission {
  private readonly verb: PermissionVerb;
  private readonly noun: string;
  private readonly context: PermissionContext;
  private readonly requriement: boolean;

  constructor(verb: PermissionVerb, noun: string, context: PermissionContext = PermissionContext.Any, requirement: boolean = false) {
    this.verb = verb;
    this.noun = noun;
    this.context = context;
    this.requriement = requirement;
  }

  static fromString(permissionString: string): Permission {
    const split = permissionString.split(':');
    const verb: PermissionVerb = split[0] as PermissionVerb;
    const noun: string = split[1];
    const context: PermissionContext = split.length === 3 ? split[2] as PermissionContext : PermissionContext.Any;
    return new Permission(verb, noun, context);
  };

  equals(permission: Permission | string): boolean {
    if (typeof permission === 'string') {
      return this.equals(Permission.fromString(permission));
    } else {
      if (this.verb !== PermissionVerb.Any && permission.verb !== PermissionVerb.Any && this.verb !== permission.verb) {
        return false;
      }
      if (this.noun !== '*' && permission.noun !== '*' && this.noun !== permission.noun) {
        return false;
      }
      if (this.requriement && permission.requriement) {
        if (this.context !== permission.context) {
          return false;
        }
      }
      if (this.requriement) {
        if (permission.context !== PermissionContext.Any && this.context !== permission.context) {
          return false;
        }
      }
      if (permission.requriement) {
        if (this.context !== PermissionContext.Any && this.context !== permission.context) {
          return false;
        }
      }
      if (this.context !== PermissionContext.Any && permission.context !== PermissionContext.Any && this.context !== permission.context) {
        return false;
      }
      return true;
    }
  }
}
